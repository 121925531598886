// Fonts
$font-regular: 'BrandonGrotesque-Regular', sans-serif;
$font-medium: 'BrandonGrotesque-Medium', sans-serif;
$font-black: 'BrandonGrotesque-Black', sans-serif;
$font-bold: 'BrandonGrotesque-Bold', sans-serif;
$font-light: 'BrandonGrotesque-Light', sans-serif;
$futura-book: 'Futura-StdBook', sans-serif;
$helvetica-thin: 'Helvetica-Thin', sans-serif;
$helvetica-light: 'Helvetica-Light', sans-serif;
$helvetica-medium: 'Helvetica-Medium', sans-serif;


// Font sizes
$cart-font-size: 19px;


// Sizes
$header-height: 60px;


// Colors:
$color-black: #252525;
$text-black: #6e6e6e;
$color-validation: #a94442;
