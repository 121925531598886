// Footer
footer {
  background: #252525;
  padding: 55px 0;
  color: #fff;
  font-family: $helvetica-thin;

  .content {
    @include clearfix; }

  .footer-part-holder {
    @extend .col-md-4;

    .footer-part {
      padding: 0 30px;

      @include mq(tablet) {
        margin-bottom: 35px; }

      @include mq(phone-big) {
        padding: 0; } }

    h4 {
      font-size: 25px;
      padding-bottom: 20px;
      margin-bottom: 30px;
      border-bottom: 1px solid #fff;
      letter-spacing: 0.05em;

      @include mq(tablet) {
        font-size: 24px;
        padding-bottom: 15px;
        margin-bottom: 20px; } }

    p {
      font-size: 19px;

      a {
        margin-bottom: 20px;
        display: block; } }

    input[type="email"] {
      height: 30px;
      width: 100%;
      margin-bottom: 20px;
      border: none;
      outline: none;
      font-family: $helvetica-thin;
      padding: 0 10px;
      font-size: 17px;
      line-height: 30px; }

    input[type="submit"] {
      width: 60px;
      height: 30px;
      float: right;
      background: #fff;
      border: none;
      outline: none;
      padding: 0;
      font-family: $helvetica-thin;
      font-size: 19px;
      line-height: 35px;
      cursor: pointer; }

    ul {
      li {
        a {
          display: block;
          font-size: 19px;
          margin-bottom: 10px;
          @include transition(all .2s linear); }

        &:hover {
          color: #BCBCBC; } } } } }

.footer-icon {
  font-size: 24px;
  margin-right: 15px;
  @include transition(all .2s linear);

  &:hover {
    color: #a5a5a5; } }
