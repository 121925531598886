// Faq page
.faq-wrap {
  min-height: 500px;

  h3 {
    font-size: 30px;
    padding-bottom: 20px;
    @include transition(.2s linear);

    @include mq(tablet-small) {
      font-size: 24px; }

    &:hover {
      cursor: pointer;
      opacity: 0.5; }

    &.ui-accordion-header-active {
      span.active {
        display: inline-block; }
      span.inactive {
        display: none; } }

    &.ui-accordion-header-collapsed {
      span.inactive {
        display: inline-block; }
      span.active {
        display: none; } } }

  p {
    font-size: 26px;
    line-height: 1.4;
    margin-bottom: 30px;

    @include mq(tablet-small) {
      font-size: 18px; } } }


// Contact
#gmap {
  height: 550px;
  width: 100%;

  @include mq(tablet) {
    height: 350px; } }

.contact-wrap {
  margin-top: 0; }

.contact-form {
  @extend .container;
  @include clearfix;
  border: 1px solid #000;
  padding: 65px 60px;
  margin: -150px auto 60px;
  position: relative;
  background: #fff;

  @include mq(tablet) {
    padding: 25px; }

  .form-holder {
    float: left;
    width: 70%;
    margin-right: 5%;

    @include mq(tablet) {
      width: 100%;
      margin: 0 0 25px; }

    .four-inputs {
      @include clearfix;

      .input-wrap {
        float: left;
        width: 50%;
        padding-right: 20px;
        margin-bottom: 15px;

        @include mq(phablet) {
          width: 100%;
          padding-right: 0; }

        label {
          font-size: 19px;
          display: block;
          margin-bottom: 5px;

          @include mq(tablet) {
            font-size: 18px; }

          span {
            color: red; } }

        input {
          width: 100%;
          height: 40px;
          padding: 0 10px;

          @include mq(tablet) {
            height: 35px; } } } }


    .textarea-holder {
      padding-right: 20px;
      margin-bottom: 40px;

      @include mq(phablet) {
        padding-right: 0; }

      label {
        font-size: 19px;
        display: block;
        margin-bottom: 5px;

        span {
          color: red; } }

      textarea {
        width: 100%;
        height: 300px;
        resize: none;
        padding: 20px;

        @include mq(tablet) {
          height: 150px; } } }

    input[type="submit"] {
      display: block;
      float: right;
      background: #000;
      color: #fff;
      height: 40px;
      text-align: center;
      border: none;
      margin-right: 20px;
      width: 150px;
      font-size: 18px;
      font-family: $font-medium;
      @include transition(all .2s linear);
      cursor: pointer;

      @include mq(phablet) {
        float: none;
        margin: 0 auto 30px; }

      &:hover {
        background: #fff;
        color: #252525; } } }

  .address-holder {
    float: left;
    width: 25%;
    padding-top: 60px;
    padding-left: 40px;

    @include mq(tablet) {
      padding: 0;
      width: 100%;
      float: none;
      text-align: center; }

    .text-holder {
      margin-bottom: 40px;

      p {
        font-size: 18px;
        font-family: $font-medium;
        margin-bottom: 5px; } } } }

// Static pages
.static-pages {
  min-height: 600px;

  p {
    font-size: 22px;
    line-height: 1.5;
    margin-bottom: 45px; }

  ul li {
    font-size: 22px;
    line-height: 1.6;
    margin-bottom: 5px;

    &:last-child {
      margin-bottom: 40px; } }

  h5 {
    font-size: 25px;
    font-family: $font-regular;
    margin-bottom: 20px;

    b {
      font-family: $font-medium;
      font-size: 28px;
      margin-bottom: 20px; } } }

//Size modal
.modal-wrap {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.5);

  .modal-content, .modal-info-size-content {
    width: 500px;
    padding: 40px 50px;
    background: #252525;
    color: #fff;
    @include position(absolute, 50% null null 50%);
    @include transform(translate(-50%,-50%));

    &.modal-info-size-content {
      top: 52%; }

    .size-modal-closer {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      font-size: 30px;
      cursor: pointer; }

    .size-info-modal-closer {
      position: absolute;
      top: 10px;
      right: 10px;
      color: #fff;
      font-size: 30px;
      cursor: pointer; }

    @include mq(laptop) {
      width: 99%;
      @include transform(translate(-50%,0));
      top: 15px !important; }

    @include mq(tablet) {
      height: 90vh;
      overflow-y: scroll;
      padding-bottom: 40px; }

    p, h5, h4 {
      color: #f8f8f8;
      font-size: 18px; }

    h5 {
      text-align: center;
      margin-bottom: 40px; }

    h4 {
      font-size: 18px;
      margin-bottom: 5px; }

    p {
      margin-bottom: 40px; }

    .table {
      border-spacing: 0;
      border-collapse: collapse;
      border: 1px solid white;

      td {
        padding: 5px;
        border: 1px solid white;
        text-align: center; }

      tr {
        border: 1px solid white; }

      tr.bold-row td {
        font-weight: bold; } }

    .main-wrap {
      @include clearfix;

      //.video-wrap
      //  float: left
      //  width: 60%
      //  margin-right: 10%
      //
      //  +mq(tablet)
      //    float: none
      //    max-width: 400px
      //    width: 95%
      //    margin: 0 auto 25px
      //    text-align: center

      .form-holder {
        @include clearfix;
        //float: left
        width: 100%;

        @include mq(tablet) {
          width: 100%;
          margin-top: 0; }

        .input-wrap {
          @include mq(tablet) {
            width: 200px;
            margin: 0 auto; } }

        .single-img-wrap {
          margin-bottom: 15px;

          img {
            max-width: 120px;
            display: block;
            margin: 0 auto 10px; }

          label {
            display: block;
            margin-bottom: 8px;
            color: #f8f8f8;
            font-size: 17px;

            span {
              color: red; } }

          input {
            width: 100%;
            height: 35px;
            padding: 0 10px;
            border: none;
            background: #fff;
            line-height: 35px;
            font-size: 25px;
            color: #000;
            text-align: center; } }

        button#addToCart {
          float: right;
          width: 105px;
          height: 40px;
          color: #fff;
          background: #252525;
          border: 2px solid #fff;
          @include transition(all .2s linear);

          @include mq(tablet) {
            display: block;
            float: none;
            margin: 0 auto 30px; }

          &:hover {
            background: #fff;
            color: #252525;
            cursor: pointer; } } } } }

  .modal-info-size-content {
    width: 755px;

    @include mq(tablet) {
      height: 90vh;
      overflow-y: scroll;
      padding: 20px 20px 40px 20px;
      width: 99%;

      iframe {
        width: 90%;
        height: auto; }

      table {
        table-layout: fixed;
        overflow-x: auto;
        display: block; }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word; } }

    @include mq(phone-big) {
      height: 90vh;
      overflow-y: scroll;
      padding: 20px 20px 40px 20px;
      width: 99%;

      iframe {
        width: 90%;
        height: auto; }

      table {
        table-layout: fixed;
        overflow-x: auto;
        display: block; }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word; } }

    @include mq(phablet) {
      height: 90vh;
      overflow-y: scroll;
      padding: 20px 20px 40px 20px;
      width: 99%;

      iframe {
        width: 90%;
        height: auto; }

      table {
        table-layout: fixed;
        overflow-x: auto;
        display: block; }

      td {
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word; } } } }

.suc-fail {
  padding-top: 60px;
  text-align: center;

  p {
    font-size: 47px;
    text-align: center;
    margin-bottom: 30px; }

  i {
    font-size: 90px;

    &.fa-check-circle {
      color: #87D37C; }

    &.fa-times-circle {
      color: #EF4836; } } }
