//Login
.login-box-holder, .shipping-holder, .profile-acc-holder {
  @include clearfix;
  border: 1px solid #000;
  margin-bottom: 100px;
  min-height: 470px;

  h4 {
    text-align: center;
    margin-bottom: 35px;
    font-size: 19px;

    @include mq(tablet-small); }

  .login-reg-box, .login-reg-box-3 {

    width: 50%;
    float: left;
    height: 500px;
    padding: 70px 100px;

    @include mq(tablet) {
      padding: 50px 20px; }

    @include mq(760) {
      float: none;
      width: 100%;
      height: auto; }

    &:last-of-type {
      border-left: 1px solid #000;

      @include mq(760) {
        border-left: none;
        border-top: 1px solid #000; } }

    p {
      text-align: center;
      font-family: $font-light;
      font-size: 19px;
      line-height: 1.2;
      margin-bottom: 30px; } }



  .login-btn {
    display: block;
    background: #252525;
    color: #fff;
    height: 40px;
    line-height: 40px;
    width: auto;
    padding: 0 30px;
    cursor: pointer;
    border: none;
    @include transition(all .2s linear);

    &:hover {
      opacity: 0.8; } }

  .login-btn-holder {
    text-align: center;

    .login-btn {
      display: inline-block; } }

  .input-wrap {
    margin-bottom: 12px;

    label {
      display: block;
      font-size: 18px;
      font-family: $font-light;
      margin-bottom: 7px; }

    input {
      width: 100%;
      height: 40px;
      line-height: 40px;
      padding: 0 15px;
      border: 1px solid #252525; } }

  .login-bottom {
    @include clearfix;

    .login-btn {
      float: right; } }

  .login-reg-box-3 {
    width: 33%;
    padding: 70px 25px;

    @include mq(760) {
      float: none;
      width: 100%;
      height: auto; } }

  .login-reg-box-3.second-of-type {
    border-left: 1px solid #000;

    @include mq(760) {
      border-left: none;
      border-top: 1px solid #000; } } }
