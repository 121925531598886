//Fonts
@font-face {
  font-family: 'BrandonGrotesque-Regular';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Regular.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Medium';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Medium.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Black';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Black.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Bold';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Bold.otf'); }

@font-face {
  font-family: 'BrandonGrotesque-Light';
  src: url('fonts/brandonGrotesque/Brandon Grotesque Light.otf'); }

@font-face {
  font-family: 'Helvetica-Thin';
  src: url('fonts/Helvetica Neue/HelveticaNeueLTPro-Th.otf'); }

@font-face {
  font-family: 'Helvetica-Medium';
  src: url('fonts/Helvetica Neue/HelveticaNeueLTPro-Md.otf'); }

@font-face {
  font-family: 'Helvetica-Light';
  src: url('fonts/Helvetica Neue/HelveticaNeueLTPro-Lt.otf'); }

@font-face {
  font-family: 'Futura-StdBook';
  src: url('fonts/Futura/FuturaStd-Book.otf'); }



//General settings
body {
  font-family: $font-regular;
  color: #000;
  background: #fff; }

.content {
  @extend .container; }

.content-full {
  @extend .container-fluid; }


//Slick slider settings
.slick-slider {
  background: url(plugins/slick/ajax-loader.gif);
  .slick-list {
    opacity: 0;
    position: absolute; }

  &.slick-initialized {
    background: none;

    .slick-list {
      opacity: 1;
      position: relative;
      transition: opacity 0.2s ease-in; } } }

.home-slides {
  visibility: hidden; }

.slick-initialized {
  visibility: visible; }

.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  background-color: #fff; }

.hamburger:hover {
  opacity: 1; }

.hamburger {
  display: block;
  margin: 0 auto;
  padding-top: 10px;
  position: relative;
  z-index: 99;

  @include mq(tablet) {
    position: absolute;
    right: 15px;
    top: 10px; } }

.video-container {
  position: relative;
  padding-bottom: 56.25%;
  padding-top: 30px;
  height: 0;
  overflow: hidden;
  iframe, object, embed {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; } }
