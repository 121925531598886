// Shipping address
.shipping-holder {
  border: none;
  height: auto;
  margin-bottom: 0;

  .form-bottom {
    margin-bottom: 10px;

    &.shipping-bottom {
      padding-right: 245px; } } }

.current-addresses {
  padding: 0 245px;

  > p {
    font: {
      family: $helvetica-thin;
      size: 19px; }
    color: #252525;
    margin-bottom: 10px; }

  @include mq(laptop-small) {
    padding: 0 115px; }

  @include mq(tablet) {
    padding: 0 35px; }

  @include mq(phone-big) {
    padding: 0 20px; } }

.addresses-holder {
  @include clearfix;
  border: 1px solid #252525;
  padding: 20px 20px 0;

  > div {
    float: left;
    width: 50%; }

  .checkbox-holder {
    display: block;
    float: left; }

  .reg-info {
    display: block;
    float: left; }

  .reg-info-holder {
    margin-bottom: 20px;

    a {
      span {
        font-size: 23px; } } } }


