// Choose address
.choose-address-holder {
  height: 597px; }

.reg-info-holder {
  text-align: center;
  margin-bottom: 40px;

  &.active {

    .checkbox-inner {
      @include checked; } } }


.checkbox-holder {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 18px;
  height: 18px;
  border: 1px solid #000;
  border-radius: 100%;
  margin-right: 20px; }



.reg-info {
  display: inline-block;
  text-align: left;
  vertical-align: top;

  p {
    font: {
      family: $font-medium;
      size: 17px; }
    margin-bottom: 5px; } }


.choose-address-holder {

  .form-holder {
    display: none; }

  div[data-address="address-new"].active ~ .form-holder {
    display: block; } }

.choose-address-holder {

  .form-bottom {

    button {

      display: block;
      background: #252525;
      color: #fff;
      height: 40px;
      line-height: 40px;
      width: 135px;
      margin: 0 auto;
      padding: 0 30px;
      cursor: pointer;
      border: none;
      @include transition(all .2s linear);

      &:hover {
        opacity: 0.8; } } } }
