// Cart
.inner-page-wrap {

  h2 {
    font: {
      family: $helvetica-thin;
      size: 33px; }
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 20px;

    @include mq(tablet-small) {
      font-size: 28px; } } }

.cart-wrap {
  border: 1px solid #252525;
  width: 100%;
  margin-bottom: 90px;

  &.is-empty {
    height: 600px;
    padding-top: 80px;

    @include mq(tablet) {
      height: auto;
      padding: 60px 0; }

    p {
      font-family: $helvetica-thin;
      font-size: 26px;
      text-align: center; } }

  .row-holder {
    @include clearfix;
    border-bottom: 1px solid #252525;
    font: {
      family: $helvetica-thin;
      size: 19px; }
    color: #252525;

    @include mq(tablet) {
      padding: 50px 0; }

    .article-pic {
      @extend .col-md-6; }

    .article-price, .article-quantity, .article-sum {
      @extend .col-md-2;
      text-align: center; }

    &.header {

      @include mq(tablet) {
        display: none; }

      > div {
        height: 40px;
        line-height: 40px; } }



    &.product {
      position: relative;

      i.cart-remove {
        content: '\f057';
        font-family: 'FontAwesome';
        @include position(absolute, 15px 20px null null);
        font-size: 24px;
        cursor: pointer;
        z-index: 99; }

      .article-price, .article-quantity, .article-sum {
        padding-top: 10%;
        font-size: $cart-font-size;

        @include mq(tablet) {
          padding-top: 0;
          margin-bottom: 30px; } }

      .img-holder {
        margin-left: 50px;
        width: 270px;
        height: 270px;

        @include mq(tablet) {
          width: 100%;
          margin-left: 0;
          margin-bottom: 30px; }

        &:before {
          @include mq(tablet) {
            content: 'Artikel:';
            display: block;
            text-align: center; } }

        img {
          max-width: 270px;
          position: absolute;
          overflow: hidden;

          @include mq(tablet) {
            width: 270px;
            margin: 0 auto;
            left: 50%;
            @include transform(translate(-50%,0)); } } }

      .article-price {

        &:before {
          @include mq(tablet) {
            content: 'Preis / Stk.:';
            display: block;
            text-align: center; } } }

      .article-quantity {

        &:before {
          @include mq(tablet) {
            content: 'Menge:';
            display: block;
            text-align: center; } } }

      .article-sum {

        &:before {
          @include mq(tablet) {
            content: 'Preis:';
            display: block;
            text-align: center; } } } } }


  .cart-sum {
    @include clearfix;
    padding-bottom: 40px;

    @include mq(tablet) {
      padding: 40px 0;
      text-align: center; }

    .coupon-code-holder {
      float: left;
      padding-left: 80px;
      padding-top: 50px;

      @include mq(tablet) {
        float: none;
        padding: 0; }

      div {
        margin-bottom: 15px;

        @include mq(tablet) {
          display: inline-block; }

        @include mq(540) {
          display: block; } }

      label {
        font: {
          family: $helvetica-light;
          size: $cart-font-size; }
        color: #252525;
        margin-right: 35px;

        @include mq(tablet) {
          margin-right: 5px; }

        @include mq(540) {
          display: block;
          margin-right: 0;
          margin-bottom: 10px; } }

      input[type="text"] {
        width: 250px;
        height: 40px;
        font-size: 19px;
        text-align: center;
        font-family: $helvetica-thin; }

      input[type="submit"], input[type="button"] {
        height: 40px;
        width: 70px;
        background: #252525;
        color: #fff;
        border: none;
        outline: none;
        float: right;
        cursor: pointer;
        padding: 0;
        font-size: 20px;

        @include mq(tablet) {
          float: none;
          vertical-align: top; } }

      input[type="button"] {
        width: 160px; } }

    .to-checkout {
      float: right;
      padding-right: 57px;
      padding-top: 60px;

      @include mq(tablet) {
        float: none;
        padding-right: 0;
        display: inline-block; }

      .total-cost {
        @include clearfix;
        margin-bottom: 27px;

        span {
          display: block;
          float: left;
          font: {
            family: $helvetica-light;
            size: $cart-font-size; }
          color: #252525;
          margin-right: 20px; }

        p {
          float: left;
          font: {
            family: $helvetica-medium;
            size: $cart-font-size; }
          color: #252525; } }

      a, button {
        display: block;
        cursor: pointer;
        line-height: 40px;
        text-align: center;
        width: 100%;
        background: #252525;
        color: #fff;
        border: none;
        outline: none;
        height: 40px;
        padding: 0;
        font-size: 19px;
        @include transition(all .2s linear);

        &:hover {
          opacity: 0.8; } } } } }

.agb-inline a {
  display: inline-block !important;
  width: auto !important;
  padding: 0;
  height: auto !important;
  font-size: 16px !important;
  color: #4183D7 !important;
  background: none !important; }

#swissBillingOrder {
  width: 300px;
  height: 75px;
  margin: 5px auto 0 auto; }

#saveOrder {
  width: 342px;
  height: 61px;
  margin: 5px auto 0 auto; }

#loader {
  display: none;
  width: 150px;
  margin: 0 auto; }

#pay-by-card,
#pay-by-invoice {
  display: block;
  border: 1px solid #252525;
  padding: 15px;

  span {
    font-family: "Helvetica-Light", sans-serif;
    font-size: 19px;
    color: #252525; } }

#pay-by-invoice {
  border-top: none; }
