// Order page
.order-nav {
  margin: 0 auto 50px;
  position: relative;
  width: 600px;

  @include mq(phablet) {
    display: none; }

  &:after {
    content: '';
    display: block;
    @include position(absolute, null null -12px 50%);
    @include transform(translate(-50%, 0));
    height: 1px;
    width: 65%;
    background: #000; }

  ul {
    @include clearfix;

    li {
      position: relative;
      width: 100px;
      float: left;
      margin: 0 50px;
      font: {
        family: $font-medium;
        size: 20px; }
      text-align: center;

      &:after {
        @include position(absolute, null null -18px 50%);
        @include transform(translate(-50%,0));
        display: block;
        content: '';
        width: 14px;
        height: 14px;
        background: #ccc;
        border-radius: 50%;
        z-index: 99; }

      &.active:after {
        background: #000; }

      &:last-child {
        margin-right: 0; } } } }

.buttons-holder {
  @include clearfix;
  width: 100%;
  margin-bottom: 30px;

  a {
    width: 50% !important;
    float: left;
    text-align: center;

    &.reg-btn {
      background: #fff;
      color: #000;
      border: 1px solid #000; } } }

.order-reg {

  .newsletter-checkbox {
    width: 60%; } }

p.min-title {
  font-family: $font-medium;
  font-size: 19px;
  color: #252525;
  margin-bottom: 10px;

  &.first {
    padding-left: 15px; } }

.cart-wrap.order-cart .row-holder.product::before {
  display: none; }

.cart-wrap.order-cart {
  position: relative;

  a.back-btn {
    @include position(absolute, 0 null null 0);
    display: block;
    width: 80px;
    height: 40px;
    background: #252525;
    text-align: center;

    i {
      color: #fff;
      font-size: 36px; } } }

.cart-wrap.order-cart .row-holder {
  .article-pic {

    p {
      padding-left: 100px; } } }

.order-cart {

  .order-cart-info {
    float: left;
    padding-left: 70px;
    padding-top: 50px;

    h4 {
      font-size: 26px;
      font-family: $helvetica-thin;
      margin-bottom: 25px; }

    p {
      font-size: 17px;
      font-family: $font-medium;
      margin-bottom: 5px; } }

  .pay-method {
    padding-left: 70px;
    padding-top: 50px;

    font-size: 20px;

    span {
      font-family: $font-bold; } } }
