@import 'homepage';
@import 'all-shoes';
@import 'cart';
@import 'login';
@import 'registration';
@import 'choose-address';
@import 'profile';
@import 'profile-order';
@import 'shipping-address';
@import 'profile-acc';
@import 'order';
@import 'order-payment';
@import 'create-shoe';
@import 'new-pages';
@import 'new';
