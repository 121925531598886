//Create shoe page
//.create-page

.shoe-wrap {
  @include clearfix;
  margin-top: 10px;
  margin-bottom: 140px;

  .left-content {
    @extend .col-md-4; }

  .right-content {
    @extend .col-md-8;
    padding: 0; }

  h4 {
    font-family: $font-light;
    font-size: 22px;
    margin-bottom: 20px;
    color: #333; } }

.details-wrap {
  margin-bottom: 25px;

  @include mq(tablet) {
    //overflow-y: scroll
    width: 100%; }

  .details-holder {
    @include clearfix;
    margin-left: -5px;
    margin-right: -5px;

    @include mq(tablet) {
      margin: 0;
      //width: 720px
      width: 100%; }

    @include mq(phablet) {
      //width: 600px
      width: 100%; }

    .single-detail-holder {
      display: block;
      float: left;
      padding: 0 5px;
      margin-bottom: 10px;

      &.active {
        & .single-detail {
          box-shadow: 0 1px 4px 4px rgba(red, 0.4); } } }

    .single-detail {
      box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
      padding-top: 10px;
      width: 110px;
      height: 110px;
      overflow: hidden;
      @include transition(all .2s ease-out);

      @include mq(phablet) {
        width: 90px;
        height: 90px; }

      &:hover {
        box-shadow: 0 1px 4px 4px rgba(0, 0, 0, 0.2); }


      p {
        color: rgba(51,51,51,0.8);
        font-family: $font-medium;
        font-size: 20px;
        text-align: center;
        position: relative;
        z-index: 1;
        cursor: pointer;

        @include mq(phone-big) {
          font-size: 14px; } }

      img {
        max-width: 100%;
        cursor: pointer;

        @include mq(phablet) {
          margin-top: -10px; }

        @include mq(phone-big) {
          margin-top: -5px; } } } } }

.material-wrap {
  float: left;
  //position: absolute
  position: relative;
  left: -75px;
  z-index: 99;

  @include mq(laptop-small) {
    left: -50px; }

  @include mq(tablet) {
    position: relative;
    left: 0; }

  @include mq(phablet) {
    float: none;
    text-align: center;
    margin-bottom: 30px; } }

.material-holder {
  //font-size: 0

  .single-material-holder {
    display: inline-block;
    margin-right: 15px;
    cursor: pointer;

    &.active {

      .material-pic {
        border-color: red !important; } }

    &:nth-child(3) {
 }      //display: none

    &:last-child {
      margin-right: 0; }

    .single-material {
      width: 70px;

      .material-pic {
        position: relative;
        box-shadow: 0 1px 3px 1px rgba(0, 0, 0, 0.2);
        display: block;
        margin: 0 auto 10px;
        width: 65px;
        height: 65px;
        border: 2px solid #fff;
        border-radius: 4px;
        overflow: hidden;
        @include transition(all .2s ease-out);

        @include mq(phablet) {
          width: 45px;
          height: 45px; }

        &:hover {
          box-shadow: 0 1px 4px 4px rgba(0, 0, 0, 0.2); }

        img {
          @include position(absolute, 50% null null 50%);
          @include transform(translate(-50%,-50%));
          width: 70px;
          height: 70px; } }

      p {
        text-align: center;
        font-size: 18px; } } } }

.please-select-part {
  font-size: 22px; }

.price-wrap {
  position: absolute;
  top: 0;
  right: 0;

  @include mq(tablet) {
    position: relative;
    float: left;
    width: calc(100% - 150px); }

  p {
    font-size: 30px;
    padding-left: 10px;
    text-align: right;

    @include mq(tablet) {
      text-align: left; }

    @include mq(phablet) {
      font-size: 22px; } } }

.right-content {
  @include clearfix;
  position: relative; }

.big-img-holder {
  position: relative;
  //height: 552px
  overflow: hidden;
  //width: 800px
  width: 100%;
  height: 470px;

  @include mq(tablet) {
    margin: 0 auto;
    width: 460px;
    height: 280px; }

  @include mq(540) {
    width: 340px;
    height: 260px; }

  @include mq(phone-small) {
    width: 280px;
    height: 230px; }

  .main-pic, .layer {
    @include position(absolute, 45% null null 50%);
    @include transform(translate(-50%,-50%));
    max-width: 100%;

    @include mq(tablet) {
      top: 50%; } }

  //& .layer
 }  //  position: absolute

.under-top {
  @include clearfix; }

.under-picture {
  @include clearfix;
  margin-top: 10px;
  position: relative;

  @include mq(tablet) {
    margin-top: 0; } }

.colors-wrap {
  @include clearfix;
  float: right;
  margin-bottom: 25px;
  max-width: 570px;
  position: relative;
  z-index: 99;

  @include mq(phablet) {
    float: none;
    text-align: center; }

  h4 {
    text-align: right;
    margin-bottom: 10px;

    @include mq(phablet) {
      text-align: center; } }

  .colors-holder {
    @include clearfix;

    @include mq(phablet) {
      text-align: center; } }

  .single-color {
    width: 32px;
    height: 32px;
    float: right;
    margin-left: 6px;
    margin-top: 6px;
    cursor: pointer;

    @include mq(phablet) {
      float: none;
      display: inline-block;
      margin: 6px; }

    &.green {
      background: green; }

    &.red {
      background: red; }

    &.blue {
      background: blue; }

    img {
      cursor: pointer; } } }

.buy-btn-holder {
  float: right;

  @include mq(phablet) {
    float: none; }

  a {
    display: block;
    background: #252525;
    color: #fff;
    border: none;
    outline: none;
    height: 50px;
    line-height: 50px;
    padding: 0 25px;
    font-size: 22px;
    @include transition(all .2s ease-out);

    @include mq(phablet) {
      width: 240px;
      margin: 0 auto;
      padding: 0;
      text-align: center; }

    &:hover {
      opacity: 0.8; } } }

#color-preview {
  float: left;
  overflow: hidden;
  width: 109px;
  height: 109px;
  margin-top: 5px;

  img {
    width: 100%;
    height: 100%; } }

#materialColors {
  max-width: 460px;
  float: right; }

/** tooltip hover * */

[data-tooltip], .colors-wrap .single-color {
  position: relative;
  cursor: pointer; }

/* Base styles for the entire tooltip */

[data-tooltip] {
  &:before, &:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    /*-webkit-transition:
     *        opacity 0.2s ease-in-out,
     *        visibility 0.2s ease-in-out,
     *        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
     *-moz-transition:
     *        opacity 0.2s ease-in-out,
     *        visibility 0.2s ease-in-out,
     *        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
     *transition:
     *        opacity 0.2s ease-in-out,
     *        visibility 0.2s ease-in-out,
     *        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24); */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none; } }

.colors-wrap .single-color {
  &:before, &:after {
    position: absolute;
    visibility: hidden;
    -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    /*-webkit-transition:
     *        opacity 0.2s ease-in-out,
     *        visibility 0.2s ease-in-out,
     *        -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
     *-moz-transition:
     *        opacity 0.2s ease-in-out,
     *        visibility 0.2s ease-in-out,
     *        -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
     *transition:
     *        opacity 0.2s ease-in-out,
     *        visibility 0.2s ease-in-out,
     *        transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24); */
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    pointer-events: none; } }

/* Show the entire tooltip on hover and focus */

[data-tooltip] {
  &:hover {
    &:before, &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; } }
  &:focus {
    &:before, &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; } } }

.colors-wrap .single-color {
  &:hover {
    &:before, &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; } }
  &:focus {
    &:before, &:after {
      visibility: visible;
      -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1; } }
  &:before {
    z-index: 99999999;
    border: 6px solid transparent;
    background: transparent;
    content: ""; } }

/* Base styles for the tooltip's directional arrow */

[data-tooltip]:before {
  z-index: 99999999;
  border: 6px solid transparent;
  background: transparent;
  content: ""; }

/* Base styles for the tooltip's content area */

.colors-wrap .single-color:after {
  z-index: 1000;
  padding: 8px;
  width: 160px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-color-title);
  font-size: 14px;
  line-height: 1.2; }

[data-tooltip] {
  &:after {
    z-index: 1000;
    padding: 8px;
    width: 160px;
    background-color: #000;
    background-color: hsla(0, 0%, 20%, 0.9);
    color: #fff;
    content: attr(data-color-title);
    font-size: 14px;
    line-height: 1.2; }
  &:before, &:after {
    bottom: 100%;
    left: 50%;
    text-align: center; } }

/* Directions */

/* Top (default) */

.colors-wrap {
  .single-color {
    &:before, &:after {
      bottom: 100%;
      left: 50%;
      text-align: center; } }
  .single-color-top {
    &:before, &:after {
      bottom: 100%;
      left: 50%;
      text-align: center; } } }

[data-tooltip]:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9); }

.colors-wrap {
  .single-color:before, .single-color-top:before {
    margin-left: -6px;
    margin-bottom: -12px;
    border-top-color: #000;
    border-top-color: hsla(0, 0%, 20%, 0.9); } }

[data-tooltip]:after {
  margin-left: -80px; }

.colors-wrap {
  .single-color:after, .single-color-top:after {
    margin-left: -80px; } }

[data-tooltip] {
  &:hover {
    &:before, &:after {
      -webkit-transform: translateY(-12px);
      -moz-transform: translateY(-12px);
      transform: translateY(-12px);
      z-index: 9999999;
      text-align: center; } }
  &:focus {
    &:before, &:after {
      -webkit-transform: translateY(-12px);
      -moz-transform: translateY(-12px);
      transform: translateY(-12px);
      z-index: 9999999;
      text-align: center; } } }

.colors-wrap {
  .single-color {
    &:hover {
      &:before, &:after {
        -webkit-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        transform: translateY(-12px);
        z-index: 9999999;
        text-align: center; } }
    &:focus {
      &:before, &:after {
        -webkit-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        transform: translateY(-12px);
        z-index: 9999999;
        text-align: center; } } }
  .single-color-top {
    &:hover {
      &:before, &:after {
        -webkit-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        transform: translateY(-12px);
        z-index: 9999999;
        text-align: center; } }
    &:focus {
      &:before, &:after {
        -webkit-transform: translateY(-12px);
        -moz-transform: translateY(-12px);
        transform: translateY(-12px);
        z-index: 9999999;
        text-align: center; } } } }

#free-shipping {
  float: left;
  width: 200px;

  @include mq(phablet) {
    float: none;
    margin: 20px auto; } }
