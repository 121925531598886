// Navigation effect
/* Overlay style */

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba(0,0,0, 0.93);
  z-index: -1;

  .overlay-close {
    width: 40px;
    height: 40px;
    position: absolute;
    right: 40px;
    top: 100px;
    overflow: hidden;
    border: none;
    background: url(images/cross.png) no-repeat center center;
    background-size: 60%;
    text-indent: 200%;
    color: transparent;
    outline: none;
    z-index: 100;
    cursor: pointer; }
  nav {
    text-align: center;
    position: relative;
    top: 175px;
    height: 60%;
    font-size: 35px;

    @include mq(tablet) {
      top: 85px;
      font-size: 28px; } }
  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    height: 100%;
    position: relative;
    li {
      display: block;

      height: 60px;
      min-height: 54px;

      &.locale-flag {
        height: auto !important; }

      @include mq(tablet) {
        height: 40px;
        min-height: 40px; }

      a {
        font-weight: 300;
        display: block;
        color: #fff;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        &:hover, &:focus {
          color: #BCBCBC; } }
      form {
        display: block;
        color: #fff;
        -webkit-transition: color 0.2s;
        transition: color 0.2s;
        &:hover, &:focus {
          color: #BCBCBC; } }
      .submit-flag {
        width: 32px;
        height: 32px;
        position: relative;
        border: 0;
        cursor: pointer;
        white-space: nowrap;
        overflow: hidden;
        text-indent: -9999px;
        &.flag-de {
          background: url(/assets/images/flag_de.png) no-repeat center center;
          background-size: contain; }
        &.flag-en {
          background: url(/assets/images/flag_en.png) no-repeat center center;
          background-size: contain; }
        &.flag-fr {
          background: url(/assets/images/flag_fr.png) no-repeat center center;
          background-size: contain; }
        &.flag-it {
          background: url(/assets/images/flag_it.png) no-repeat center center;
          background-size: contain; } } } } }

/* Overlay closing cross */

/* Menu style */

/* Effects */

.overlay-hugeinc {
  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.5s, visibility 0s 0.5s;
  transition: opacity 0.5s, visibility 0s 0.5s;
  &.open {
    opacity: 1;
    visibility: visible;
    -webkit-transition: opacity 0.5s;
    transition: opacity 0.5s; }
  nav {
    -webkit-perspective: 1200px;
    perspective: 1200px;
    ul {
      opacity: 0.4;
      -webkit-transform: translateY(-25%) rotateX(35deg);
      transform: translateY(-25%) rotateX(35deg);
      -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
      transition: transform 0.5s, opacity 0.5s; } }
  &.open nav ul {
    opacity: 1;
    -webkit-transform: rotateX(0deg);
    transform: rotateX(0deg); }
  &.close nav ul {
    -webkit-transform: translateY(25%) rotateX(-35deg);
    transform: translateY(25%) rotateX(-35deg); } }

//@media screen and (max-height: 30.5em)
//  .overlay
//    nav
//      height: 70%
//      font-size: 34px
//    ul li
//      min-height: 34px
