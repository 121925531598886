//Profile acc
.profile-acc-holder {
  border: none;
  margin-bottom: 0;

  ::-webkit-input-placeholder /* Chrome/Opera/Safari */ {
    font-size: 17px;
    color: #252525;
    font-family: $font-light; }

  ::-moz-placeholder /* Firefox 19+ */ {
    font-size: 17px;
    color: #252525;
    font-family: $font-light; }

  -ms-input-placeholder:  /* IE 10+ */ {
    font-size: 17px;
    color: #252525;
    font-family: $font-light; }

  -moz-placeholder:  /* Firefox 18- */ {
    font-size: 17px;
    color: #252525;
    font-family: $font-light; }

  .reg-birth {

    .acc-btn {
      float: right;
      margin-right: 15px; } }




  .acc-change-info {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #000; }


  .change-password p {
    font-size: 18px;
    font-family: $font-light;
    margin-bottom: 7px;
    padding-left: 15px; } }

.url-btn {
  display: block;
  background: #252525;
  color: #fff;
  height: 40px;
  line-height: 40px;
  width: auto;
  padding: 0 30px;
  cursor: pointer;
  border: none;
  @include transition(all .2s linear);

  &:hover {
    opacity: 0.8; } }
