//New css
.size-select-wrap {
  z-index: 99;
  position: absolute;
  right: 35px;
  top: 100px;
  background: url(http://i62.tinypic.com/15xvbd5.png) no-repeat 92% 0;
  height: 29px;
  overflow: hidden;
  width: 120px;
  background-color: #252525;
  color: #fff;
  font-family: $font-black;
  cursor: pointer;

  &:hover {
    cursor: pointer; }

  @include mq(tablet) {
    position: relative;
    float: right;
    top: 0;
    margin-right: 20px; }

  select {
    background: transparent;
    border: none;
    font-size: 20px;
    height: 29px;
    padding: 0 5px;
    width: 140px;
    color: #fff;
    font-family: $futura-book;

    @include mq(phablet) {
      font-size: 18px; }

    option {
      background: #D8D7D8;
      font-size: 20px;
      text-align: right;
      padding-right: 5px;
      color: #222;

      @include mq(phablet) {
        font-size: 16px;
        text-align: left; } } } }

#size-info {
  width: 28px;
  height: 28px;
  z-index: 99;
  position: absolute;
  right: 0;
  top: 100px;
  cursor: pointer;

  @include mq(tablet) {
    position: relative;
    float: right;
    top: -29px;
    margin-right: 20px; } }

.avatar-holder {
  position: relative;
  width: 33%;
  float: right;

  a.avatar-icon {
    width: 100%; }

  .logout-btn {
    display: none; }

  &:hover .logout-btn {
    display: block;
    position: absolute;
    top: 60px;
    left: 50%;
    width: 150px;
    height: 40px;
    line-height: 40px;
    font-size: 22px;
    text-align: center;
    background: #252525;
    color: #fff;
    transform: translate(-50%,-4px);
    border: 1px solid #fff;
    @include transition(all .2s linear);

    &:hover {
      color: #a5a5a5; } } }


header a {
  @include transition(all .2s linear);

  &:hover {
    color: #a5a5a5; } }

