//Header
header {
  position: relative;
  z-index: 999;

  height: $header-height;
  background: $color-black;
  color: #fff;

  @include mq(tablet) {
    height: 65px; }

  .content-full {
    position: relative;
    background: #252525;
    @include clearfix;
    padding: 0; }

  .home-button {
    @include position(absolute, 0 null null 0);
    display: block;
    float: left;
    width: 220px;
    border-right: 1px solid #fff;
    height: $header-height;

    @include mq(laptop-small) {
      width: 100px; }

    @include mq(tablet) {
      padding-top: 8px;
      border-right: 0;
      z-index: 11; }

    span {
      @include position(absolute, 50% null null 50%);
      @include transform(translate(-50%,-50%));
      font-size: 30px; } }

  .top-buttons {
    @include position(absolute, 0 0 null null);
    @include clearfix;
    float: right;
    width: 320px;
    border-left: 1px solid #fff;
    height: $header-height;

    @include mq(laptop-small) {
      width: 150px; }

    @include mq(tablet) {
      display: none; }

    .locale-flag {
      float: left;
      position: relative;
      height: $header-height;
      width: 33%;

      form {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        cursor: pointer;

        .submit-flag {
          width: 30px;
          height: 30px;
          border: 0;
          cursor: pointer;
          white-space: nowrap;
          overflow: hidden;
          text-indent: -9999px;
          margin: 15px auto;
          display: block;

          &.flag-de {
            background: url(/assets/images/flag_de.png) no-repeat center center;
            background-size: contain; }
          &.flag-en {
            background: url(/assets/images/flag_en.png) no-repeat center center;
            background-size: contain; }
          &.flag-fr {
            background: url(/assets/images/flag_fr.png) no-repeat center center;
            background-size: contain; }
          &.flag-it {
            background: url(/assets/images/flag_it.png) no-repeat center center;
            background-size: contain; } } } }

    a {
      position: relative;
      float: right;
      width: 33%;
      height: $header-height;
      display: block;

      &.active:after {
        top: 100%;
        left: 50%;
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(37, 37, 37, 0);
        border-top-color: #252525;
        border-width: 20px;
        margin-left: -20px; }

      span {
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        font-size: 30px; }

      span.not-number {
        position: absolute;
        bottom: 0px;
        right: 25px;
        left: auto;
        top: auto;
        background: #e80000;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        color: #fff;

        b {
          position: absolute;
          top: 11px;
          left: 50%;
          transform: translate(-50%,-50%);
          font-size: 12px;
          font-weight: 100; } } } }

  .top-nav-holder {
    @include clearfix;
    padding: 0 320px 0 220px;
    height: $header-height;

    @include mq(laptop-small) {
      padding: 0 160px; }

    @include mq(tablet) {
      display: none; }

    a {
      position: relative;
      display: block;
      width: 50%;
      float: left;
      text-align: center;
      height: $header-height;
      font: {
        family: $font-bold;
        size: 20px; }
      text-transform: uppercase;
      line-height: $header-height;



      &.men-shoes-link {
        padding-right: 130px;

        &.active:after {
          top: 100%;
          left: calc(50% - 72px);
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(37, 37, 37, 0);
          border-top-color: #252525;
          border-width: 20px;
          margin-left: -20px; } }

      &.women-shoes-link {
        padding-left: 90px;

        &.active:after {
          top: 100%;
          left: calc(50% + 72px);
          border: solid transparent;
          content: " ";
          height: 0;
          width: 0;
          position: absolute;
          pointer-events: none;
          border-color: rgba(37, 37, 37, 0);
          border-top-color: #252525;
          border-width: 20px;
          margin-left: -20px; } } } }

  .header-logo-holder {
    @include position(absolute, 0 null nul 50%);
    @include transform(translate(-50%,0));
    width: 220px;
    height: 60px;
    background: $color-black;
    z-index: 9;
    padding-top: 7px;

    @include mq(tablet) {
      position: relative;
      top: initial;
      left: initial;
      @include transform(translate(0,0));
      width: 100%;
      height: 65px; }

    .logo-holder {
      width: 130px;
      height: 102px;
      margin: -8px auto 0;

      @include mq(tablet) {
        width: 100px;
        margin: 0 auto;
        height: 65px; }

      svg {
        @include mq(tablet) {
          margin-top: -17px; } }

      .st0, .st1 {
        fill: #fff; } }

    i {
      margin: 0 auto;
      display: block;
      text-align: center;
      font-size: 31px;
      @include transform(scale(1,1));
      @include transition(all .1s ease-in-out); }

    &:hover {
      cursor: pointer;

      i {
        @include transform(scale(1.1,1.1)); } } } }

#main-nav {
  position: fixed;
  top: $header-height;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0,0,0,0.93); }

//Mobile menu
.mobile-only {
  display: none !important;

  @include mq(tablet) {
    display: block !important; } }

.logo-holder {
  width: 80px;
  height: 65px;
  margin: -8px auto 0;

  @include mq(tablet) {
    margin: 0 auto; }

  svg {
    @include mq(tablet) {
      margin-top: -17px; } }

  .st0, .st1 {
    fill: #fff; } }
