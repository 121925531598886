//responsive media queries
@mixin mq($width) {
  @if $width == desktop {
    @media only screen and (max-width: 1440px) {
      @content; } }
  @else if $width == laptop {
    @media only screen and (max-width: 1366px) {
      @content; } }
  @else if $width == laptop-small {
    @media only screen and (max-width: 1200px) {
      @content; } }
  @else if $width == tablet-big {
    @media only screen and (max-width: 1024px) {
      @content; } }
  @else if $width == tablet {
    @media only screen and (max-width: 992px) {
      @content; } }
  @else if $width == tablet-small {
    @media only screen and (max-width: 768px) {
      @content; } }
  @else if $width == 760 {
    @media only screen and (max-width: 760px) {
      @content; } }
  @else if $width == phablet {
    @media only screen and (max-width: 635px) {
      @content; } }
  @else if $width == 540 {
    @media only screen and (max-width: 540px) {
      @content; } }
  @else if $width == phone-big {
    @media only screen and (max-width: 480px) {
      @content; } }
  @else if $width == phone {
    @media only screen and (max-width: 415px) {
      @content; } }
  @else if $width == phone-small {
    @media only screen and (max-width: 360px) {
      @content; } }
  @else if $width == phone-smallest {
    @media only screen and (max-width: 320px) {
      @content; } } }

//titles


//box shadow
@mixin box-shadow {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2); }

@mixin box-shadow-hover {
  box-shadow: 0 2px 4px 1px rgba(0, 0, 0, 0.2); }


//underline hover
@mixin underline-hover {

  &.active:before {
    content: '';
    display: block;
    position: absolute;
    width: 90%;
    height: 2px;
    bottom: 0;
    left: 5%;
    background-color: #000;
    visibility: visible;
    @include transform(scaleX(1)); }

  &:hover {
    color: #000; }

  &:before {
    content: '';
    position: absolute;
    width: 90%;
    height: 2px;
    bottom: 0;
    left: 5%;
    background-color: #000;
    visibility: hidden;
    @include transform(scaleX(0));
    @include transition(all .3s ease-in-out 0s); }


  &:hover:before {
    visibility: visible;
    @include transform(scaleX(1)); } }

@mixin checked {
  width: 10px;
  height: 10px;
  background: #000;
  border-radius: 100%;
  @include position(absolute, 50% null null 50%);
  @include transform(translate(-50%,-50%)); }
