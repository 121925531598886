//Registration
.registration-holder, .choose-address-holder, .shipping-holder, .profile-acc-holder {
  position: relative;
  padding-top: 40px;

  a.back-btn {
    @include position(absolute, 0 null null 0);
    display: block;
    width: 80px;
    height: 40px;
    background: #252525;
    text-align: center;

    i {
      color: #fff;
      font-size: 36px; } }

  form {
    @include clearfix;
    padding: 0 230px;

    @include mq(laptop-small) {
      padding: 0 100px; }

    @include mq(tablet) {
      padding: 0 20px; }

    @include mq(phone-big) {
      padding: 0; } }

  .col2 {
    @extend .col-md-2;
    @extend .col-sm-2; }

  .col4 {
    @extend .col-md-4;
    @extend .col-sm-4; }

  .col6 {
    @extend .col-md-6;
    @extend .col-sm-6; }

  .reg-birth {
    @include clearfix;

    p {
      display: block;
      font-size: 18px;
      font-family: $font-light;
      margin-bottom: 7px;
      padding-left: 15px; } }

  label, p {
    span {
      color: #F22613; } }

  .form-bottom {
    @include clearfix;
    padding: 0 15px;
    margin: 20px 0 40px;

    .newsletter-checkbox {
      float: left;

      @include mq(phone-big) {
        float: none;
        margin-bottom: 20px; } }

    .login-btn {
      float: right;

      @include mq(phone-big) {
        float: none;
        display: inline-block; } } } }

.input-wrap .select2-container, .select2-container .select2-selection--single {
  width: 100% !important;
  height: 40px;
  border-radius: 0;
  border-color: #252525; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 40px; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 40px; }

.select2-search.select2-search--dropdown {
  display: none; }

.validation-error {
  color: $color-validation; }

.row-holder {
  @include clearfix;
  margin: 0; }
