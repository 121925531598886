// Profile
.profile-wrap {
  border: 1px solid #252525;
  padding: 20px 0;
  min-height: 680px;
  margin-bottom: 60px; }

.profile-top-holder {
  border-bottom: 1px solid #252525;

  .profile-nav {
    @include clearfix;
    padding: 0 100px;

    @include mq(tablet) {
      padding: 0 20px; }

    @include mq(phone-big) {
      padding: 0; }

    li {
      float: left;
      width: 25%;

      @include mq(tablet) {
        width: 50%; }

      a {
        @include underline-hover;
        position: relative;
        display: block;
        padding: 0 30px 10px;
        margin-bottom: 15px;

        @include mq(phone-big) {
          padding: 0 10px 10px; }

        .icon-holder {
          position: relative;
          width: 100px;
          height: 100px;
          margin: 0 auto;

          @include mq(tablet) {
            width: 70px;
            height: 70px; }

          span {
            @include position(absolute, 50% null null 50%);
            @include transform(translate(-50%,-50%));
            font-size: 65px;

            @include mq(tablet) {
              font-size: 42px; }

            &.icon-dashboard {
              font-size: 85px;

              @include mq(tablet) {
                font-size: 60px; } } } }

        p {
          font-size: 19px;
          text-align: center;

          @include mq(tablet) {
            font-size: 17px; }

          @include mq(phone-big) {
            font-size: 15px; } } } } } }

.profile-overview-holder {

  .member-name {
    font: {
      family: $font-medium;
      size: 19px; }
    color: #252525;
    padding: 50px 0;
    text-align: center; } }

.newsletter-choose {
  text-align: center;
  cursor: pointer;




  > div {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top; }

  > p {
    display: inline-block;
    color: #252525;
    @include transition(all 0.2s ease-out); }

  &:hover p {
    color: #BCBCBC; }

  &.active {

    .checkbox-inner {
      @include checked; } } }

.newsletter-activate {
  text-align: center;
  margin-bottom: 60px;
  font-family: $helvetica-light;

  > div, > span {
    display: inline-block;
    vertical-align: middle;
    font-size: 20px;
    margin-right: 30px;

    @include mq(phablet) {
      font-size: 18px; }

    @include mq(phone-big) {
      width: 100%;
      margin-bottom: 10px; } }

  .icon-holder {
    font-size: 55px;

    @include mq(phablet) {
      width: 100%; }
    margin-bottom: 15px; } }


.profile-overview-bottom {

  > p {
    font: {
      family: $font-light;
      size: 20px; }
    text-align: center;
    margin-bottom: 30px; }

  .reg-info-holder {

    margin-bottom: 15px; }

  .edit-address {
    margin-bottom: 50px;
    text-align: center;

    span {
      font-size: 20px; } } }
