@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon/icomoon.eot?95lnsn');
  src: url('fonts//icomoon/icomoon.eot?95lnsn#iefix') format("embedded-opentype"), url('fonts/icomoon/icomoon.ttf?95lnsn') format("truetype"), url('fonts/icomoon/icomoon.woff?95lnsn') format("woff"), url('fonts/icomoon/icomoon.svg?95lnsn#icomoon') format("svg");
  font-weight: normal;
  font-style: normal; }



[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-avatar_full:before {
  content: "\e901"; }

.icon-home_full:before {
  content: "\e902"; }

.icon-shopping-cart:before {
  content: "\e903"; }

.icon-logo_josh:before {
  content: "\e900"; }

.icon-avatar:before {
  content: "\e904"; }

.icon-checked:before {
  content: "\e905"; }

.icon-coins {
  .path1:before {
    content: "\e906";
    color: rgb(241, 205, 113); }
  .path2:before {
    content: "\e907";
    margin-left: -1em;
    color: rgb(239, 199, 94); }
  .path3:before {
    content: "\e908";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path4:before {
    content: "\e909";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path5:before {
    content: "\e90a";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path6:before {
    content: "\e90b";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path7:before {
    content: "\e90c";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path8:before {
    content: "\e90d";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path9:before {
    content: "\e90e";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path10:before {
    content: "\e90f";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path11:before {
    content: "\e910";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path12:before {
    content: "\e911";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path13:before {
    content: "\e912";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path14:before {
    content: "\e913";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path15:before {
    content: "\e914";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path16:before {
    content: "\e915";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path17:before {
    content: "\e916";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path18:before {
    content: "\e917";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path19:before {
    content: "\e918";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path20:before {
    content: "\e919";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path21:before {
    content: "\e91a";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path22:before {
    content: "\e91b";
    margin-left: -1em;
    color: rgb(203, 169, 80); }
  .path23:before {
    content: "\e91c";
    margin-left: -1em;
    color: rgb(203, 169, 80); } }

.icon-dashboard:before {
  content: "\e91d"; }

.icon-edit:before {
  content: "\e91e"; }

.icon-home:before {
  content: "\e91f"; }

.icon-home2:before {
  content: "\e920"; }

.icon-mastercard {
  .path1:before {
    content: "\e921";
    color: rgb(226, 87, 76); }
  .path2:before {
    content: "\e922";
    margin-left: -1em;
    color: rgb(244, 180, 89); } }

.icon-newsletter:before {
  content: "\e923"; }

.icon-paypal {
  .path1:before {
    content: "\e924";
    color: rgb(38, 166, 209); }
  .path2:before {
    content: "\e925";
    margin-left: -1em;
    color: rgb(50, 77, 91); }
  .path3:before {
    content: "\e926";
    margin-left: -1em;
    color: rgb(50, 77, 91); } }

.icon-shop:before {
  content: "\e927"; }

.icon-trash:before {
  content: "\e928"; }

.icon-visa {
  .path1:before {
    content: "\e929";
    color: rgb(35, 148, 188); }
  .path2:before {
    content: "\e92a";
    margin-left: -1em;
    color: rgb(239, 199, 94); } }
