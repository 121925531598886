// Slider settings
.home-slides {

  .slick-slide {

    img {
      width: 100%; } }

  .slick-prev, .slick-next {
    width: 30px;
    height: 50px;
    background: rgba(235,235,235,0.5);
    z-index: 9;
    @include transition(all .1s linear);

    &:hover {
      background: rgba(235,235,235,1); }

    &:hover:after {
      color: rgba(0,0,0,1); }
    &:hover:after {
      color: rgba(0,0,0,1); } }

  .slick-prev::before, .slick-next::before {
    content: '';
    font-size: 0; }

  .slick-prev::after, .slick-next::after {
    font: {
      family: 'FontAwesome';
      size: 31px; }
    color: rgba(0,0,0,0.5);
    line-height: 40px;
    @include transition(all .1s linear); }



  .slick-prev::after {
    content: '\f104';
    padding-right: 3px; }

  .slick-next::after {
    content: '\f105';
    padding-left: 3px; }

  .slick-prev {
    left: 20px; }

  .slick-next {
    right: 20px; } }

// Featured products
.featured-products {

  @include mq(laptop-small) {
    text-align: center;
    font-size: 0; }

  .content-full {
    @include clearfix;
    padding: 0;

    .featured-product {
      display: block;
      width: 20%;
      float: left;

      @include mq(laptop-small) {
        display: inline-block;
        width: 25%;
        float: none; }

      @include mq(tablet-small) {
        width: 33.3333%; }

      @include mq(540) {
        width: 50%; }

      img {
        max-width: 100%;
        @include transform(scale(1));
        @include transition(all .2s ease-out); }

      &:hover {
        img {
          @include transform(scale(0.9)); } } } } }
