// Profile order
.profile-orders-holder {

  .order-line {
    @include clearfix;
    height: 50px;
    border-bottom: 1px solid #252525;
    line-height: 50px;

    > div {
      float: left;
      width: 20%;

      p {
        font: {
          family: $font-light;
          size: 19px; }
        color: #252525;
        text-align: center; }

      a {
        display: block;
        text-align: center;
        text-transform: uppercase;
        text-decoration: underline;
        font: {
          family: $font-medium;
          size: 19px; }
        color: #252525; }

      &.price {

        p {
          font: {
            family: $font-medium; }
          text-transform: uppercase; } } } } }

// Order detail
.order-detail-holder {

  .detail-order-info-holder {
    @include clearfix;
    border-bottom: 1px solid #252525;

    .back-btn {
      display: block;
      width: 7%;
      height: 40px;
      background: #252525;
      text-align: center;
      float: left;

      i {
        color: #fff;
        font-size: 36px; } }

    > div {
      float: left;
      width: 31%;

      p {
        font: {
          family: $font-light;
          size: 19px; }
        color: #252525;
        text-align: center;
        line-height: 40px; } } }

  .product-details {
    .row-holder {
      @include clearfix;
      border-bottom: 1px solid #252525;
      font: {
        family: $helvetica-thin;
        size: 19px; }
      color: #252525;

      .article-pic {
        @extend .col-md-6; }

      .article-price, .article-quantity, .article-sum {
        @extend .col-md-2;
        text-align: center; }

      &.header {

        > div {
          height: 50px;
          line-height: 50px; } }

      &.product {
        position: relative;

        .article-price, .article-quantity, .article-sum {
          padding-top: 10%;
          font-size: $cart-font-size; }

        .img-holder {
          margin-left: 50px;
          width: 270px;
          height: 270px;

          img {
            max-width: 270px;
            position: absolute;
            overflow: hidden; } } } } }


  .product-details-bottom {
    @include clearfix;
    padding: 40px 60px 20px;


    .pdf-btn {
      display: block;
      float: left;
      font: {
        family: $helvetica-thin;
        size: 19px; }
      color: #252525;
      text-decoration: underline; }

    .total-cost {
      @include clearfix;
      float: right;
      font: {
        family: $helvetica-thin;
        size: 19px; }
      color: #252525;

      span {
        float: left;
        margin-right: 100px; }

      p {
        float: right; } } } }
