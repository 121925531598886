// Order payment

.payment-holder {
  text-align: center;

  > p {

    font-size: 20px;
    margin-bottom: 30px; }

  > a {
    display: inline-block !important; }

  .payment-single-holder {
    @include clearfix;
    position: relative;
    border: 1px solid #707070;
    width: 280px;
    height: 100px;
    display: inline-block;
    margin: 0 30px 30px 30px;


    .pic-holder {
      position: relative;
      width: 50%;
      float: left;
      height: 100%;

      img {
        @include position(absolute, 50% null null 50%);
        @include transform(translate(-50%,-50%));
        max-width: 80%;
        display: block; } }

    p {
      width: 50%;
      float: left;
      text-align: left;
      line-height: 100px;
      font-size: 19px;
      font-family: $font-bold; }

    .check-icon {
      display: none; }

    &.active .check-icon {
      display: block;
      @include position(absolute, -13px -13px null null);
      background: #fff;
      font-size: 30px;
      z-index: 99;
      color: #1ad810; } } }
