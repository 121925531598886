// All shoes
.inner-page-wrap {
  margin-top: 30px;

  @include mq(tablet) {
    margin-top: 60px; }

  .shoes-list-holder {
    @include clearfix;

    .single-shoe-holder {
      @extend .col-lg-3;
      @extend .col-md-4;
      @extend .col-xs-6;

      @include mq(phablet) {
        width: 290px;
        margin: 0 auto 20px;
        float: none; }


      margin-bottom: 30px;

      .single-shoe {
        @include box-shadow();
        background: #fff;
        padding: 30px 0 20px;
        border: 3px solid #fff;
        @include transition(all .2s linear 0s);


        h5 {
          font: {
            family: $font-bold;
            size: 20px; }
          text-align: center;
          color: $text-black;
          text-transform: uppercase;
          margin-bottom: 5px; }

        span {
          font: {
            family: $font-bold;
            size: 16px; }
          line-height: 16px;
          color: $text-black;
          display: block;
          text-align: center;
          margin-bottom: 10px; }

        p {
          font: {
            family: $font-light;
            size: 20px; }
          color: $text-black;
          text-align: center; }

        .img-holder {
          width: 240px;
          margin: 0 auto 10px;
          padding: 20px;
          height: 173px;
          position: relative;

          img {
            @include position(absolute, 50% null null 50%);
            @include transform(translate(-50%,-50%));
            max-width: 100%; } }

        .btn-holder {
          width: 215px;
          height: 52px;
          margin: 0 auto;
          border: 2px solid #fff;
          position: relative;
          z-index: 99;
          @include transition(all .2s ease-in-out);

          a {
            display: block;
            border: 1px solid #474747;
            text-align: center;
            line-height: 46px;
            font: {
              family: $futura-book;
              size: 17px; }
            color: #333;
            @include transition(all .2s ease-in-out); }

          &:hover {
            background: #333;

            a {
              border-color: #fff;
              color: #fff; } } }

        &:hover {
          @include box-shadow-hover;
          border-color: #d1d1d1;
          border-radius: 3px; } } } } }
